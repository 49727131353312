import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

// Import function style
import "./styles/functions/styles.css";
import "./styles/functions/fontStyles.css";

// Import header_footer style
import "./styles/header_footer/navStyles.css";

// Import content style
import "./styles/content/homeStyles.css";
import "./styles/content/aboutStyles.css";
import "./styles/content/skillsStyles.css";
import "./styles/content/projectStyles.css";
import "./styles/content/warningStyles.css";

// Import routing
import { HashRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
