import { Link, useMatch, useResolvedPath } from "react-router-dom"
import { BsFillPersonFill, BsCollectionFill, BsAward, BsPin, BsFileEarmarkPdfFill } from "react-icons/bs"
import { useEffect, useState } from "react";

export default function Navbar()
{
    const [navOpen, setNavOpen] = useState(false);
    const [isHamburg, setHamburg] = useState(false);
    useState(() => {         
        const updateScreen = () => {
            if (window.innerWidth <= 1100)
                setHamburg(true);
            else
                setHamburg(false)
        }
        window.addEventListener('resize', updateScreen);
    }, [])
    useEffect(() => {
        if (window.innerWidth <= 1100)
            setHamburg(true);
    }, [])

    return (
    <nav className = "nav">
        <div className="nav-container">
            <Link to="/" className = "site-title" onClick={() => setNavOpen(false)}>
                <div className="site-title-container">
                    <div className = "site-title-initial">Seow</div>
                    <div className = "site-title-back">SK</div>
                </div>
            </Link>
            <div className="toggle-button" onClick={() => setNavOpen(!navOpen)}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>
        </div>
        <div className = "expanded" style={{
            animation: isHamburg ? 
            (navOpen ?
                "slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both" :
                "slide-out-nav 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both"
            ) : 
            undefined
        }}>
            <ul>
                <CustomLink to="/about" onClick={() => setNavOpen(false)}>
                    <div className="navLinks">
                        <BsFillPersonFill/>
                        <span>About</span>
                    </div>
                </CustomLink>
                <CustomLink to="/skills" onClick={() => setNavOpen(false)}>
                    <div className="navLinks">
                        <BsAward/>
                        <span>Skills</span>
                    </div>
                </CustomLink>
                <CustomLink to="/projects" onClick={() => setNavOpen(false)}>
                    <div className="navLinks">
                        <BsCollectionFill/>
                        <span>Project</span>
                    </div>
                </CustomLink>
                <CustomLink to="https://drive.google.com/file/d/1HsPskw3Nvz30JXRfJ_LnEQlOqWrV7ngp/view?usp=sharing" 
                    onClick={() => {setNavOpen(false);}}>
                    <div className="navLinks">
                        <BsFileEarmarkPdfFill/>
                        <span>Resume</span>
                    </div>
                </CustomLink>
                <CustomLink to="https://seow-sinkiat.com" onClick={() => setNavOpen(false)}>
                    <div className="navLinks">
                        <BsPin/>
                        <span>Dashboard</span>
                    </div>
                </CustomLink>
            </ul>
        </div>
    </nav>
    );
}

function CustomLink({to, children, ...props})
{
    //const path = window.location.pathname;
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch( {path: resolvedPath.pathname, end: true})
    return (
        <li className={isActive ? "active" : ""}>
            <Link to = {to} {...props}>{children}</Link>
        </li>
    )
}