import ALine from "../functions/Line";

import { useState, useEffect } from "react";

// import JSON
import skillsPageJson from '../../configs/skills.json';

export default function Skills() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [Est, setEst] = useState([])

    return (
        <div className="SkillsPage">
            {
                skillsPageJson.map((skillGroup, skillGroupIndex) => {
                    return (
                        <div className="skillsContainer">
                            {skillGroupIndex !== 0 ? <ALine /> : <></>}
                            <div className="skillsTitle">{skillGroup["name"]}</div>
                            <div className="skillsContainer1">
                                {
                                    skillGroup["content"].map((skills, skillsIndex) => {
                                        return (
                                            skills["type"] === "text" ?
                                                <div className="skillsLogoContainer1">
                                                    <div className='skillsNameTextExt'>{skills["name"]}</div>
                                                </div>
                                                : skills["type"] === "image" ?
                                                    <SkillsStyle
                                                        oME={() => setEst({ Est, [String(skillGroupIndex + "-" + skillsIndex)]: true })}
                                                        oML={() => setEst({ Est, [String(skillGroupIndex + "-" + skillsIndex)]: false })}
                                                        status={Est[String(skillGroupIndex + "-" + skillsIndex)]}
                                                        icon={skills["image"]}
                                                        name={skills["name"]} />
                                                    : skills["type"] === "noimage" ?
                                                        <div className="skillsLogoContainer"
                                                            onMouseEnter={() => setEst({ Est, [String(skillGroupIndex + "-" + skillsIndex)]: true })}
                                                            onMouseLeave={() => setEst({ Est, [String(skillGroupIndex + "-" + skillsIndex)]: false })}>
                                                            <div className="skillCont">
                                                                <div className="skillsLogo">{skills["name"]}</div>
                                                            </div>
                                                            <div className={Est[String(skillGroupIndex + "-" + skillsIndex)] ? 'skillsNameExt' : 'skillsName'}>{skills["name"]}</div>
                                                        </div>
                                                        : <></>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}

function SkillsStyle({ oME, oML, status, icon, name, ...props }) {
    return (
        <div className="skillsLogoContainer" loading="lazy" onMouseEnter={oME} onMouseLeave={oML}>
            <div className="skillCont">
                <img alt="" className="skillsLogo" src={icon} />
            </div>
            <div className={status ? 'skillsNameExt' : 'skillsName'}>{name}</div>
        </div>
    )
};