// Import header and footer
import Navbar from './pages/header_footer/Navbar';
import Footer from './pages/header_footer/Footer';

// Import content
import Home from "./pages/content/Home";
import About from "./pages/content/About";
import Skills from "./pages/content/Skills";
import Warnings from './pages/content/Warning';
import Projects from "./pages/content/Projects";

import { Route, Routes, useLocation } from "react-router-dom"

function App() {
    const loadingScreen = document.getElementById("loadingScreen");
    const loadingScreen2 = document.getElementById("loadingScreen2");
    const loadingScreenTxt = document.getElementById("loadingScreenTxt");
    const location = useLocation();
    if (loadingScreen) {
        setTimeout(() => {
            loadingScreenTxt.className = "loadingScreenText";
        }, 1000)
        setTimeout(() => {
            loadingScreen2.className = "loadingScreenContainerExit";
            loadingScreen.className = "loadingScreenBGExit";
            loadingScreen.style.marginTop = "-100vh";
            loadingScreen.style.transition = "2s";
            loadingScreen.style.overflow = "hidden";
        }, 3500);
    }
    return (
        <div className='mainContainer'>
            <Navbar />
            <div className="container">
                <Routes location={location} key={location.key}>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/skills" element={<Skills />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="*" element={<Warnings />} />
                </Routes>
            </div>
            <Footer />
        </div>
    );
}

export default App;
