import { useEffect } from "react";

// import JSON
import projectPageJson from '../../configs/project.json';

export default function Projects() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="ProjectPage">
            {
                projectPageJson.map((projectData, index) => {
                    return (
                        <SiteCell
                            images={projectData["images"]}
                            title={projectData["title"]}
                            buttonInfo={projectData["buttonInfo"]}
                            buttonInactivity={projectData["buttonInactivity"]}
                            secondButton={projectData["secondButton"]}
                            secondButtonInfo={projectData["secondButtonInfo"]}
                            accessSite={projectData["accessSite"]}
                            accessSite2={projectData["accessSite2"]}
                            text1={projectData["text1"]}
                            text2={projectData["text2"]}
                        />
                    )
                })
            }
        </div>
    );
}

function SiteCell({ images, title, text1, text2, buttonInfo, buttonInactivity, secondButton, secondButtonInfo, accessSite, accessSite2, delay, opacityV, ...props }) {
    return (
        <div className="ProjectContainerPadding">
            <div className="ProjectContainer">
                <img alt="" className="ProjectInfoImg" loading="lazy" src={images} />
                <div className="ProjectContainer1">
                    <div className="ProjectInfoTitle">
                        {title}
                    </div>
                    <div className="ProjectInfoContainer">
                        <div className="ProjectInfoContainer1">
                            <div className="AnotherPP">{text1}</div>
                            <div className="AnotherPP">{text2}</div>
                        </div>
                        <div className="ProjectInfoContainer2">
                            {buttonInactivity ?
                                <a className="ProjectInfoButtonIA">
                                    {buttonInfo}
                                </a>
                                :
                                <a href={accessSite} className="ProjectInfoButton" target="noreferrer">
                                    {buttonInfo}
                                </a>
                            }
                            {secondButton ?
                                <a href={accessSite2} className="ProjectInfoButton" target="noreferrer">
                                    {secondButtonInfo}
                                </a>
                                :
                                <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}