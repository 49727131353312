import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import ALine from "../functions/Line";
import ReactTypingEffect from 'react-typing-effect';

import { useState, useEffect } from 'react';
import { SiThingiverse } from 'react-icons/si';
import { AiFillLinkedin } from 'react-icons/ai';
import { BsGithub, BsEnvelopeFill, BsSpotify } from 'react-icons/bs';

// import JSON
import homePageJson from '../../configs/home.json';

export default function Home() {
    // Scroll to the top on load
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Settings for carousel
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        adaptiveHeight: true,
        variableWidth: true,
    };

    const [Est, setEst] = useState([])

    return (
        <div className="HomePage">
            <div className="WelcomeMsgContainer">
                <div className="WelcomeMsgTextContainer">
                    <div className="WelcomeMsg1Container">
                        <div className="WelcomeMsg1_1">Greetings Visitors!</div>
                        <span className="waving">👋🏻</span>
                    </div>
                    <div className="WelcomeMsg2Container">
                        <div className="WelcomeMsg2_1">Welcome</div>
                        <span>to my site.</span>
                    </div>
                    <div className="WelcomeMsg3Container">
                        <div className="WelcomeMsg3_1">I am</div>
                        <div>
                            <div className="WelcomeMsg3_3">Seow Sin Kiat</div>
                            <ReactTypingEffect className="RTE1"
                                text={["an Engineer", "a Maker", "a Programmer"]}
                                cursorRenderer={cursor => <div>{cursor}</div>}
                                speed={100}
                                eraseSpeed={50}
                                eraseDelay={3000}
                                displayTextRenderer={(text, i) => {
                                    return (
                                        <div>
                                            {
                                                text.split('').map((char, keyIndex) => {
                                                    return (
                                                        <span key={keyIndex} >{char}</span>
                                                    );
                                                })
                                            }
                                        </div>
                                    );
                                }} />
                        </div>
                    </div>
                </div>
                <img alt={homePageJson["PROFILEIMAGE"]["alt"]}
                    className={homePageJson["PROFILEIMAGE"]["className"]}
                    src={homePageJson["PROFILEIMAGE"]["image"]}
                    loading='lazy' />
            </div>
            <ALine />
            <div className="scrolling-wrapper">
                <div className="carouselHL">Project Highlights</div>
                <Slider {...settings}>
                    {
                        homePageJson["CAROUSEL"].map((carouselData, index) => {
                            return (
                                <img key={index}
                                    alt={carouselData["alt"]}
                                    title={carouselData["title"]}
                                    src={carouselData["image"]}
                                    className={carouselData["className"]}
                                    loading="lazy" />
                            )
                        })
                    }
                </Slider>
            </div>
            <ALine />
            <div className="contactContainer">
                <div className="contactTitle">
                    Connect
                    <div className="contactTitle1">With Me!</div>
                </div>
                <div className="contactContainer2">
                    {
                        homePageJson["CONNECTION"].map((connectionData, index) => {
                            return (
                                <ConnectWM
                                    site={connectionData["site"]}
                                    oME={() => setEst({ Est, [index]: true })}
                                    oML={() => setEst({ Est, [index]: false })}
                                    status={Est[index]}
                                    name={connectionData["name"]}
                                    type={connectionData["type"]}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}

function ConnectWM({ site, oME, oML, status, name, type, ...props }) {
    return (
        <a className="homeA" href={site} target="noreferrer">
            <div className='ExtInf' onMouseEnter={oME} onMouseLeave={oML}>
                {
                    type === "GITHUB" ? <BsGithub className='InfIcon' /> :
                        type === "SPOTIFY" ? <BsSpotify className='InfIcon' /> :
                            type === "MAIL" ? <BsEnvelopeFill className='InfIcon' /> :
                                type === "LINKEDIN" ? <AiFillLinkedin className='InfIcon' /> :
                                    type === "THINGIVERSE" ? <SiThingiverse className='InfIcon' /> :
                                        <></>
                }
                <div className={status ? 'InfTxtExt' : 'InfTxt'}>{name}</div>
            </div>
        </a>
    )
};