import ALine from "../functions/Line";

import { useEffect } from 'react';

// import JSON
import aboutPageJson from '../../configs/about.json';

export default function About() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="AboutPage">
            <div className="interestContainer">
                <div className='interestImg'>
                    <img alt={aboutPageJson["SUMMARY"]["alt"]} className="intImg" src={aboutPageJson["SUMMARY"]["image"]} />
                </div>
                <div className='interestMsg'>
                    <div className='interestMsgTitle'>My Interest?</div>
                    <div className='interestMsgText'>
                        <p>{aboutPageJson["SUMMARY"]["text1"]}</p>
                        <p>{aboutPageJson["SUMMARY"]["text2"]}</p>
                    </div>
                </div>
            </div>
            {
                aboutPageJson["INVOLVEMENT"].map((involvementData, index) => {
                    return (
                        <div className="studiesInfo">
                            <ALine />
                            <div className="AbtInfoContainer">
                                <div className="AbtInfoTitle">
                                    {involvementData["name"]}
                                </div>
                                <div className="AbtInfoContainer2">
                                    <div className="AbtInfoLogoImg">
                                        <img alt={involvementData["alt"]}
                                            src={involvementData["image"]}
                                            className="AbtInfoLogoImg"
                                            loading="lazy" />
                                    </div>
                                    <div className="AbtInfoText">
                                        <div className="AbtInfoContainer3">
                                            <div className="AbtInfoText1">{involvementData["aboutInfo1"]}</div>
                                            <div className="AbtInfoText1">{involvementData["aboutInfo2"]}</div>
                                        </div>
                                        <div className="AbtInfoContainer4">
                                            <div className="AbtInfoText2">{involvementData["aboutInfo3"]}</div>
                                            <div className="AbtInfoText2">{involvementData["aboutInfo4"]}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>

    );
}
